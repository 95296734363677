import { Component, OnInit, OnDestroy, isDevMode } from '@angular/core';
import { DeviceView } from '@axks/components';
import { faSignOutAlt, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MenuItem, MessageService } from 'primeng/api';


import { ConfirmationService } from 'primeng/api';
import { interval, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { SessionPortalProv } from 'src/app/provs/session-portal-prov.service';
import { Perfil } from 'src/app/api/profile';
import { NotificacionRegistrarService } from 'src/app/client/notif-restc.service';
import { NotifPsService } from 'src/app/procs/notif-ps.service';
import { NotificacionDet } from 'src/app/api/notificaciones';
import { LoginService } from 'src/app/procs/login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'axks-system-actions',
  templateUrl: './system-actions.component.html',
  styleUrls: ['./system-actions.component.scss'],
  providers: [ConfirmationService]
})
export class SystemActionsComponent extends DeviceView implements OnInit, OnDestroy {

  faSignOutAlt = faSignOutAlt;
  faTimesCircle = faTimesCircle;

  notificaciones = null;
  //notificaciones: Notificaciones[];

  avatarMenu: MenuItem[];
  notifMenu: MenuItem[];
  notifsNumber: number;

  companyMenu: MenuItem[];

  prof: Perfil;
  gender: string;

  notifInterval: Subscription;
  private notifErrors: number = 0;

  showProfileDetail: boolean = false;
  showNotif: boolean = false;
  showAppMovil: boolean = false;
  notifPath: string;
  showMultiCompany: boolean = false;

  companyClass;

  closeSubs: Subscription;

  constructor(protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionPortalProv,
    private notifPs: NotifPsService,
    private notifRegPs: NotificacionRegistrarService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private loginSv: LoginService,
    private router: Router) {
    super(deviceService, messageService);

    this.notifInterval = interval(10000).subscribe({
      next: (data) => {
        this.initNotif();
      },
      error: (error) => {
        if (this.notifErrors == 5) {
          this.notifInterval.unsubscribe();
        }
        this.notifErrors += 1;
      }
    });

    this.closeSubs = sessionProvier.closeSession.subscribe({
      next: () => {
        // Termina la búsqueda de notificaciones
        this.notifInterval.unsubscribe();
      }
    })
  }

  ngOnInit(): void {
    //this.prof = this.getProfile();
    this.prof = this.sessionProvier.profile.value;
    let nomEmpleado;
    try {
      nomEmpleado = this.prof.nombre + " " + this.prof.apPaterno;
    } catch (e) {
      console.log("Error al recuperar el perfil...");
      nomEmpleado = '';
    }

    try {
      this.gender = this.prof.genero || 'M';
    } catch (e) {
      console.log("Error al recuperar el genero");
      this.gender = 'M';
    }

    //this.notificaciones = this.notificaionesServicio.retornar();
    this.avatarMenu = [
      { label: nomEmpleado + " \n " + this.prof.usuario, disabled: true },
      {
        label: 'Perfíl', icon: 'pi pi-user', command: (event) => {
          this.disableDialogs();
          this.showProfileDetail = true;
        }
      },
      {
        label: 'Notificación', icon: 'pi pi-pencil', command: (event) => {
          this.disableDialogs();
          this.initNotifPath();
          this.showNotif = true;
        }
      },
      {
        label: 'Aplicación móvil', icon: 'pi pi-mobile', command: (event) => {
          this.disableDialogs();
          this.showAppMovil = true;
        }
      },
      { label: 'Salir', icon: 'pi pi-power-off', command: (event) => { this.salir(event) } }
    ];
    //this.initNotif();

    this.initCompanyMenu();

  }

  ngOnDestroy(): void {
    this.notifInterval.unsubscribe();
  }

  initNotif() {

    let idEmpleado = this.prof.idEmpleado;

    this.notifPs.notificacionesEmpleado(idEmpleado).subscribe(
      (result) => {
        let notifs = result as [];
        this.notificaciones = result;
        try {
          this.notifsNumber = notifs.length;
          this.notifMenu = [] as MenuItem[];
          for (let item of notifs) {
            let nitem = {
              label: item['idNotificacion'] + ". " + item['titulo'],
              command: (event) => {
                this.detalleNotificacion(item['idNotificacion']);
              }
            } as MenuItem;
            this.notifMenu.push(nitem);
          }

        } catch (e) {
          console.error("No fue posible procesar las notificaciones.")
        }
      }
    );
  }


  salir(event) {

    let iframes = document.getElementsByTagName("iframe");
    if (iframes) {
      for (let i = 0; i < iframes.length; i++) {
        iframes.item(i).contentWindow.postMessage("endsession", "*");
      }
    }

    this.loginSv.closeSession();
  }

  invalidateSession() {
    //sessionStorage.clear();
    this.sessionProvier.invalidate()
  }

  detalleNotificacion(id: number) {
    //let profile = this.getProfile() as Perfil;
    let profile = this.sessionProvier.profile.value;

    this.notifPs.detalleNotificacion(id).subscribe(
      data => {
        let notif = data as NotificacionDet;
        let mensaje = notif.mensaje;
        let titulo = notif.titulo;
        this.confirmationService.confirm(
          {
            message: ' ' + mensaje,
            header: ' ' + titulo,
            icon: 'pi pi-exclamation-triangle'

          }
        );
        if (profile) {
          this.notifRegPs.notificaVisto(profile.idEmpleado, id).subscribe({
            next: (data) => {
            },
            error: (error) => {
              console.log("Error al notificar de visto", error);
            }
          }
          );
        }

      }
    );

  }

  onRowSelect(event) {
    let id = event.data.id;
    let titulo = event.data.titulo;
    let mensaje = event.data.mensaje

    this.confirmationService.confirm({
      message: ' ' + mensaje,
      header: ' ' + titulo,
      icon: 'pi pi-exclamation-triangle'

    });

  }

  disableDialogs() {
    this.showNotif = false;
    this.showProfileDetail = false;
    this.showAppMovil = false;
  }
  
  path: string = "blank";

  initNotifPath() {
    this.notifPath = undefined;
    //let profile = this.getProfile() as Perfil;
    let profile = this.sessionProvier.profile.value;
    let empresa = this.sessionProvier.empresa.value;//this.getEmpresaSel();
    let app = this.sessionProvier.aplicacion.value;//this.getAplicacionSel();
    let cred = this.sessionProvier.token.value.token;



    this.path = environment.notifRegGui + "?e=" + empresa + "&a=" + app + "&s=" + window.btoa(cred);

    setTimeout(() => {
      let iframe = document.getElementById("notifif") as HTMLIFrameElement;
      iframe.src = this.path;
    }, 1000);
  }

  initCompanyMenu() {
    //let prof = this.getProfile() as Perfil;
    let prof = this.sessionProvier.profile.value;
    /* console.log("Profile en company: ")
    console.log(prof);
    console.log(prof.selectedCompany)
    console.log(prof.hasMultiCompRol) */


    this.companyMenu = [];
    if (prof.hasMultiCompRol) {

      let maxkan = {
        label: 'Axkan Soluciones',
        icon: 'c-icons axkan-icon',
        command: () => { this.selCompany(1) }
      } as MenuItem;
      let msbs = {
        label: 'SbSynergy',
        icon: 'c-icons sbs-icon',
        command: () => { this.selCompany(2) }
      };
      let mhabitat = {
        label: 'Habitat Axkan',
        icon: 'c-icons habitat-icon',
        command: () => { this.selCompany(3) }
      };

      switch (this.sessionProvier.empresa.value) {
        case 1:
          this.companyMenu.push(msbs);
          this.companyMenu.push(mhabitat);
          this.companyClass = "axkan-icon";
          break;
        case 2:
          this.companyMenu.push(maxkan);
          this.companyMenu.push(mhabitat);
          this.companyClass = "sbs-icon";
          break;
        case 3:
          this.companyMenu.push(maxkan);
          this.companyMenu.push(msbs);
          this.companyClass = "habitat-icon";
          break;
        default:
          this.companyMenu.push(maxkan);
          this.companyMenu.push(msbs);
          this.companyMenu.push(mhabitat);
          this.companyClass = "axkan-icon";
          break;
      }

    }
  }

  selCompany(idEmpresa: number) {
    //let prof = this.getProfile() as Perfil;
    //let prof = this.sessionProvier.profile.value;
    //prof.selectedCompany=idEmpresa;
    this.cookieService.set("se", idEmpresa.toString());
    //this.sessionProvier.profile.next(prof);
    this.sessionProvier.empresa.next(idEmpresa);
    /* this.router.navigate(["/portal"]);

    this.initCompanyMenu(); */
    //window.location.reload();
    this.router.navigate(["/"]);
  }

  onAppAndroid() {}

  onAppiOS() {}

}
